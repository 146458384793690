<template>
  <div>
    <v-container >
      <v-row class="elevation-1">
        <v-col>
          <v-select id="mtgReportTypeSelect"
            v-model="reportConfig"
            :items="reports"
            item-text="description"
            item-value="config"
            label="Type of report"
          ></v-select>
        </v-col>
      </v-row>
      <v-row class="elevation-1"
        v-if="showReportConfig('showFiscalYears')">
        <v-col>
          <v-select id="mtgReportFYSelect"
            v-model="criteria.fy"
            :items="fiscalYearList"
            item-text="name"
            item-value="year"
            label="By Fiscal Year"
            @change="fychanged"
          ></v-select>
        </v-col>
      </v-row>
      <v-row class="elevation-1"
        v-if="showReportConfig('showFromToDates')">
        <v-col id="mtgReportFromDate">
          <ClarionDateControl
            v-model="criteria.fromDate"
            :isRequired="true" label="From Date" />
        </v-col>
          <v-col id="mtgReportToDate">
            <ClarionDateControl
              v-model="criteria.toDate"
              :isRequired="true" label="To Date" />
        </v-col>
      </v-row>
      <v-row class="elevation-1"
        v-if="showReportConfig('showVoid')">
        <v-col>
          <v-select id="mtg-criteria-isVoid"
            v-model="criteria.isVoid"
            :items="isVoidOptions"
            item-text="caption"
            item-value="value"
            label="By Type"
            >
          </v-select>
        </v-col>
      </v-row>
      <v-row class="elevation-1"
        v-if="showReportConfig('showWkst')">
        <v-col>
          <v-select id="mtg-report-by-wkst"
            v-model="criteria.wkst"
            label="By Workstation"
            item-text="wkstInt"
            item-value="_id"
            :items="wkstList"
            @change="selectedWkst"
            >
          </v-select>
        </v-col>
      </v-row>
      <v-row class="elevation-1"
        v-if="showReportConfig('showPaymentTypes')">
        <v-col>
          <MultiItemSelect id="mtg-reports-payment-types-multiselect"
            class="mtg-reports-payment-types"
            label="Payment Type (Cash, Check, Credit Card, EFT)"
            item-text="description"
            item-value="name"
            :item-list="paymentTypes"
            :items="criteria.paymentType"
            @selected-items="selectedPaymentType"
            />
          </v-col>
        </v-row>
      <v-row id="mtgViewReport">
        <ReportChip
          :to="reportConfig.route"
          :queryString="queryString"
          :isValid="validateCriteria"
          caption="View Report"
        />
      </v-row>
    </v-container>
  </div>
</template>
<script>
import { mapState, mapActions, mapGetters } from 'vuex';
import MultiItemSelect from '../../common/base/MultiItemSelect.vue';
import ReportChip from '../../common/base/report/ReportChip.vue';
import ClarionDateControl from '../../common/ClarionDateControl.vue';
import { calculateFiscalYears, toFiscalYear, toShortDate } from '../../../util/shared/tmc-global';
import { reportList, queryStringCalc } from './report-util';
import { getRawJwt } from '../../../util/jwt';

export default {
  name: 'MtgReportComp',
  components: {
    ReportChip,
    ClarionDateControl,
    MultiItemSelect,
  },
  data: () => ({
    criteria: {
      fy: null,
      fromDate: undefined,
      toDate: undefined,
      printDetails: false,
      paymentType: [],
      wkst: [],
    },
    reportConfig: {
      route: '',
      filters: {},
    },
    wkstList: [],
  }),
  created() {
    this.initializeFiscalYears();
    this.criteria.fromDate = toShortDate(this.todaysDate);
    this.criteria.toDate = toShortDate(this.todaysDate);
    this.loadPaymentTypes();
    this.loadWorkStationNumbers();
  },
  computed: {
    ...mapGetters('base/crud', [
      'items',
    ]),
    ...mapState({
      regYears: (state) => state.mtg.mtgTransaction.regYears,
      paymentTypes: (state) => state.enums.mtgPaymentTypeEnum || [],
    }),
    ...mapGetters([
      'todaysDate',
    ]),
    reports() {
      return [
        ...reportList(),
      ];
    },
    fiscalYearList() {
      const registerdYears = (this.regYears || {});
      const allowAll = false;
      return calculateFiscalYears(registerdYears, allowAll);
    },
    queryString() {
      return queryStringCalc({
        reportConfig: this.reportConfig,
        criteria: this.criteria,
        token: this.getAuthToken,
      });
    },
    validateCriteria() {
      let isValid = true;
      if (this.criteria.fy == null) {
        isValid = false;
      }
      return isValid;
    },
    getAuthToken() {
      return getRawJwt();
    },
    isVoidOptions() {
      return [
        {
          value: null,
          caption: 'All',
        },
        {
          value: true,
          caption: 'Void',
        },
      ];
    },
  },

  methods: {
    ...mapActions('mtg/mtgTransaction', [
      'loadFiscalRegYears',
      'loadWkstList',
    ]),
    mapCollectionVal(itemList, itemValue) {
      return [...(itemList || []).map((item) => item[`${itemValue}`])];
    },
    initializeFiscalYears() {
      this.loadFiscalRegYears().then(() => {
        const regYears = this.fiscalYearList || [];
        if ((regYears || []).length > 0) {
          this.criteria.fy = (regYears[regYears.length - 1] || {}).year;
        }
      }).catch((e) => {
        console.warn(e);
      });
    },
    fychanged() {
      this.loadWorkStationNumbers(this.criteria.fy);
    },
    async loadWorkStationNumbers(fy) {
      const fiscalYear = fy || toFiscalYear(new Date());
      this.wkstList = await this.loadWkstList({ fiscalYear }) || [];
      this.wkstList.unshift({
        _id: null,
        wkstInt: 'All',
      });
      this.criteria.wkst = this.mapCollectionVal(this.wkstList, '_id');
    },
    showReportConfig(configField) {
      return this.reportConfig.filters[`${configField}`];
    },
    loadPaymentTypes() {
      this.criteria.paymentType = this.mapCollectionVal(this.paymentTypes, 'name');
    },
    selectedPaymentType(val) {
      this.criteria.paymentType = val;
    },
    selectedWkst(val) {
      this.criteria.wkst = val;
    },
  },
};

</script>
