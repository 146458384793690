import { generateQueryString } from '../../common/base/report/report-util';

export const queryStringCalc = ({
  reportConfig,
  criteria,
  token,
}) => {
  const params = [];

  if (reportConfig.filters.showFromToDates && criteria.fromDate) {
    params.push(`fromDate=${criteria.fromDate}`);
  }

  if ((reportConfig.filters.showFromToDates || reportConfig.filters.showToDates)
    && criteria.toDate) {
    params.push(`toDate=${criteria.toDate}`);
  }

  if (reportConfig.filters.showFiscalYears && criteria.fy) {
    params.push(`fiscalYear=${criteria.fy}`);
  }

  if (reportConfig.filters.showWkst && criteria.wkst) {
    params.push(`wkst=${criteria.wkst}`);
  }

  if (reportConfig.filters.showPaymentTypes && criteria.paymentType) {
    params.push(`paymentType=${criteria.paymentType}`);
  }

  if (reportConfig.filters.showVoid) {
    params.push(`isVoid=${criteria.isVoid}`);
  }

  if (token) {
    params.push(`token=${token}`);
  }
  return generateQueryString(params);
};

export const reportList = () => {
  const list = [
    {
      description: 'Mortgage Tax Collections',
      config: {
        route: 'mtg/mortgage-tax-collections',
        filters: {
          showFiscalYears: true,
          showFromToDates: true,
          showPaymentTypes: true,
          showWkst: true,
          showVoid: true,
        },
      },
    },
  ];
  return list;
};

export default {
  reportList,
  queryStringCalc,
};
