<template>
  <div>
    <MtgReportComp />
  </div>
</template>

<script>
import MtgReportComp from '@/components/Mtg/Reports/MtgReportComp.vue';

export default {
  name: 'MtgReport',
  components: {
    MtgReportComp,
  },
};

</script>
